import React from 'react';
import './VideoCategoryList.css';

const VideoCategoryList = ({ categories }) => {
  return (
    <div className="category-list-container">
      {categories.map((category, index) => (
        <div className="category-item" key={index}>
          <h2>{category.name}</h2>
          <div className="videos">
            {category.videos.map((video, videoIndex) => (
              <div className="video-item" key={videoIndex}>

                <img src={`https://api.judah.tv/uploads/${video.thumbnail}`} alt={video.title} />

                <div className="video-title">{video.title}</div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default VideoCategoryList;
